<template>
    <div class="logins">
        <div class="login_from">
            <el-form :model="ruleForm" ref="ruleFormLogin" :rules="rules" label-width="85px" class="demo-ruleForm" size="medium">
                <el-form-item label="账号:" prop="mobile">
                    <el-input v-model="ruleForm.mobile" clearable></el-input>
                </el-form-item>
                <el-form-item label="密码:" prop="password">
                    <el-input v-model="ruleForm.password" show-password></el-input>
                </el-form-item>
            </el-form>
            <div class="login-btn" @click="onLogin">登录</div>
        </div>
        <footer-com></footer-com>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { aesEncrypt } from './login.js'
export default {
    name: 'Login',
    data() {
        return {
            rules: {
                mobile: [{ required: true, message: "请输入账号!", trigger: "blur" },],
                password: [{ required: true, message: "请输入密码!", trigger: "blur" }],
            },//表单校验
            ruleForm: {
                password: "",
                mobile: "",
            },//表单信息
        }
    },
    methods: {
        ...mapActions(["setInfoObj", "setDiZhiList"]),
        onLogin() {
            this.$refs.ruleFormLogin.validate(async (valid) => {
                if (valid) {
                    let form = {
                        password: this.ruleForm.password,
                        mobile: this.ruleForm.mobile,
                        userFrom:'闲物互联',
                    }
                    let pass = aesEncrypt(form.password);
                    form.companyid = 2
                    form.userFrom = '闲物互联'
                    form.password = pass
                    let result = await this.$api.Login(form);
                    if (result.code == 200) {//注册完跳转登录
                        this.$message.success("成功登录!")
                        this.onLogo(result);
                    } else {
                        this.$message.error(result.msg);
                    }
                } else {
                    console.log("请填写完整信息.");
                    return false;
                }
            });
        },
        async onLogo(result) {
            this.setInfoObj({//注册后信息存进vuex
                account: result.data.account,
                id: result.data.id,
                status: result.data.status,
                sex: result.data.sex,
                phone: result.data.phone,
                companyid: result.data.companyid,
                name:result.data.name
            });

            window.localStorage.setItem("token", result.token)
            this.$router.push({ name: "MySelf" });//注册成功跳转去登录
        },
    }
}
</script>

<style lang="scss">
.logins {
    font-size: max(14px,1vw);
    padding-top: 5%;
    .login_from {
        width: max(350px, 40vw);
        margin: 2vw auto;
        margin-bottom: 17%;
        .el-input{
            width: 80%;
        }

        .login-btn {
            width: max(160px, 30%);
            padding: 1.5% 0%;
            margin: auto;
            background-color: #4C9C51;
            text-align: center;
            border-radius: max(20px, 3vw);
            cursor: pointer;
            user-select: none;
            transform: translateX(-17%);
            @media screen and (max-width: 1400px) {
                transform: translateX(0%);
            }
        }
    }
}
</style>