import CryptoJS from 'crypto-js'

const APP_KEY = "MIGfMA0GCSqGSIb3";
const Login_USER = "user";
const Login_TOKEN = "token";
const Login_TYPE = "PC";
const URL_PREFIX = "https://zzx-1312093770.cos.ap-nanjing.myqcloud.com";

export default {
	APP_KEY,
	Login_USER,
	Login_TOKEN,
	Login_TYPE,
	URL_PREFIX
}

export function aesEncrypt(pass) { 
	const APP_KEY = "MIGfMA0GCSqGSIb3";
	const key = CryptoJS.enc.Utf8.parse(APP_KEY);
	const content = CryptoJS.enc.Utf8.parse(pass);
	const encrypted = CryptoJS.AES.encrypt(content, key, {
		mode: CryptoJS.mode.ECB,
		padding: CryptoJS.pad.Pkcs7,
	});
	return encrypted.toString();
}